import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Divider } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

function DialogoCrear(props) {
    const { theme, setMessage, getAccessTokenWithRefresh, setSeverity, setSnack, setShowBackdrop, API_DEFAULT, obtenerEstudios } = props;
    const [accessToken, setAccessToken] = props.accessToken;

    const [open, setOpen] = props.open;
    const [centroCostos, setCentroCostos] = useState('0');
    const [subcentroCostos, setSubcentroCostos] = useState('1');
    const [nombre, setNombre] = useState('');
    const [tipoCampo, setTipoCampo] = useState('Presencial');
    const [muestra, setMuestra] = useState('0');
    const [meta, setMeta] = useState('0');
    const [precioVenta, setPrecioVenta] = useState('0');
    const [presupuesto, setPresupuesto] = useState('0');
    const [tipoEstudio, setTipoEstudio] = useState('Población general');
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaCierre, setFechaCierre] = useState(null);
    const [cerrado, setCerrado] = useState('No');
    const [servidor, setServidor] = useState('Ninguno');
    const [errores, setErrores] = useState(Array(10).fill(false));

    const limpiar = () => {
        setCentroCostos('0');
        setSubcentroCostos('1');
        setNombre('');
        setTipoCampo('Presencial');
        setMuestra('0');
        setMeta('0');
        setPrecioVenta('0');
        setPresupuesto('0');
        setTipoEstudio('Población general');
        setFechaInicio(null);
        setFechaCierre(null);
        setCerrado('No');
        setServidor('Ninguno');
        setErrores(Array(8).fill(false));
        setOpen(false);
    }

    const validar = () => {
        let errorInformacion = false;
        if (centroCostos === '') {
            errorInformacion = true;
            errores[0] = true;
        }
        if (subcentroCostos === '') {
            errorInformacion = true;
            errores[1] = true;
        }
        if (nombre === '' || nombre.includes(" ")) {
            errorInformacion = true;
            errores[2] = true;
        }
        if (muestra === '') {
            errorInformacion = true;
            errores[3] = true;
        }
        if (meta === '') {
            errorInformacion = true;
            errores[4] = true;
        }
        if (precioVenta === '') {
            errorInformacion = true;
            errores[5] = true;
        }
        if (presupuesto === '') {
            errorInformacion = true;
            errores[6] = true;
        }
        if (fechaInicio === '' || fechaInicio === null ) {
            errorInformacion = true;
            errores[7] = true;
        }
        if (fechaCierre === '' || fechaCierre === null ) {
            errorInformacion = true;
            errores[8] = true;
        }
        if(fechaInicio > fechaCierre){
            errorInformacion = true;
            errores[9] = true;
        }
        
        if (errorInformacion) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            crear();
        }
    }

    const crear = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/estudios/estudios/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify([{
                'nombre_integra': nombre,
                'centro_costos': parseInt(centroCostos),
                'subcentro_costos': parseInt(subcentroCostos),
                'tipo_campo': tipoCampo === 'Presencial' ? 0 : (tipoCampo === 'Telefónico' ? 1 : (tipoCampo === 'Cualitativo' ? 4 : 2)),
                'muestra': parseInt(muestra),
                'meta': parseInt(meta),
                'precio_venta': parseFloat(precioVenta),
                'presupuesto': parseFloat(presupuesto),
                'tipo_estudio': tipoEstudio === 'Población general' ? 0 : (tipoEstudio === 'Base de clientes' ? 1 : (tipoEstudio === 'ECAR' ? 2 : 3)),
                'fecha_inicio': fechaInicio.toISOString().split('T')[0],
                'fecha_cierre': fechaCierre.toISOString().split('T')[0],
                'cerrado': cerrado === 'Si',
                'servidor': servidor === 'Ninguno' ? 0 : (servidor === 'España' ? 1 : 2)
            }])
        });

        if (res.ok) {
            obtenerEstudios(access);
            setMessage('Se ha creado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            limpiar();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        crear(newAccess);
                    }
                }
                else if (res[0]['nombre_integra'][0] === 'estudio with this nombre integra already exists.') {
                    setShowBackdrop(false);
                    setMessage('Ya existe un estudio con el mismo Nombre INTEGRA.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>Crear estudio</DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant='body1'>Centro de costos:</Typography>
                <NumberFormat
                    customInput={TextField}
                    decimalSeparator={false}
                    allowNegative={false}
                    onFocus={(e) => e.target.select()}
                    inputProps={{
                        style: {
                            textAlign: 'right'
                        }
                    }}
                    value={centroCostos}
                    onValueChange={(e) => { errores[0] = false; setErrores([...errores]); if (e.value.length <= 4) setCentroCostos(e.value) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    error={errores[0]}
                    helperText={errores[0] ? 'El centro de costos no puede quedar vacío' : null}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Subcentro de costos:</Typography>
                <NumberFormat
                    customInput={TextField}
                    decimalSeparator={false}
                    allowNegative={false}
                    onFocus={(e) => e.target.select()}
                    inputProps={{
                        style: {
                            textAlign: 'right'
                        }
                    }}
                    value={subcentroCostos}
                    onValueChange={(e) => { errores[1] = false; setErrores([...errores]); if (e.value.length <= 2) setSubcentroCostos(e.value) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    error={errores[1]}
                    helperText={errores[1] ? 'El subcentro de costos no puede quedar vacío' : null}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Nombre INTEGRA:</Typography>
                <TextField
                    value={nombre}
                    onChange={(e) => { errores[2] = false; setErrores([...errores]); setNombre(e.target.value) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    error={errores[2]}
                    helperText={errores[2] ? 'El nombre INTEGRA no puede quedar vacío o tener espacios' : null}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Tipo de campo:</Typography>
                <Autocomplete
                    value={tipoCampo}
                    onChange={(e, v) => setTipoCampo(v)}
                    options={
                        ['Presencial', 'Telefónico', 'Cualitativo', 'Mixto'].map(option => option)
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Muestra:</Typography>
                <NumberFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    decimalSeparator={false}
                    allowNegative={false}
                    onFocus={(e) => e.target.select()}
                    inputProps={{
                        style: {
                            textAlign: 'right'
                        }
                    }}
                    value={muestra}
                    onValueChange={(e) => { errores[3] = false; setErrores([...errores]); setMuestra(e.value) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    error={errores[3]}
                    helperText={errores[3] ? 'La muestra no puede quedar vacía' : null}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Meta:</Typography>
                <NumberFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    decimalSeparator={false}
                    allowNegative={false}
                    onFocus={(e) => e.target.select()}
                    inputProps={{
                        style: {
                            textAlign: 'right'
                        }
                    }}
                    value={meta}
                    onValueChange={(e) => { errores[4] = false; setErrores([...errores]); setMeta(e.value) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    error={errores[4]}
                    helperText={errores[4] ? 'La meta no puede quedar vacía' : null}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Precio de venta:</Typography>
                <CurrencyTextField
                    value={precioVenta}
                    onChange={(e, v) => { setPrecioVenta(v); errores[5] = false; setErrores([...errores]) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    modifyValueOnWheel={false}
                    outputFormat='string'
                    decimalPlaces={0}
                    minimumValue={0}
                    error={errores[5]}
                    helperText={errores[5] ? 'El precio de venta no puede quedar vacío' : null}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Presupuesto legalizaciones:</Typography>
                <Typography variant='body2' align='justify'>El presupuesto de legalizaciones se agrega desde el Aplicativo de Legalizaciones, una vez el estudio esté creado en este gestor.</Typography>
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Presupuesto:</Typography>
                <CurrencyTextField
                    value={presupuesto}
                    onChange={(e, v) => { setPresupuesto(v); errores[6] = false; setErrores([...errores]) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    modifyValueOnWheel={false}
                    outputFormat='string'
                    decimalPlaces={0}
                    minimumValue={0}
                    error={errores[6]}
                    helperText={errores[6] ? 'El presupuesto no puede quedar vacío' : null}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Tipo de estudio:</Typography>
                <Autocomplete
                    value={tipoEstudio}
                    onChange={(e, v) => setTipoEstudio(v)}
                    options={
                        ['Población general', 'Base de clientes', 'ECAR', 'Otros', 'Empresas'].map(option => option)
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Fecha de inicio:</Typography>
                <KeyboardDatePicker
                    clearable
                    autoOk
                    inputVariant='outlined'
                    size='small'
                    variant='inline'
                    fullWidth
                    maxDate={fechaCierre}
                    value={fechaInicio}
                    placeholder='dd/mm/yyyy'
                    onChange={date => { setFechaInicio(date); errores[7] = false; errores[9] = false; setErrores([...errores]) }}
                    format='dd/MM/yyyy'
                    error={errores[7]}
                    helperText={errores[7] ? 'La fecha no puede quedar vacía' : (errores[9]?'La fecha de inicio debe ser menor o igual a la fecha de cierre':null)}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Fecha de cierre:</Typography>
                <KeyboardDatePicker
                    clearable
                    autoOk
                    inputVariant='outlined'
                    size='small'
                    variant='inline'
                    fullWidth
                    minDate={fechaInicio}
                    value={fechaCierre}
                    placeholder='dd/mm/yyyy'
                    onChange={date => { setFechaCierre(date); errores[8] = false; errores[9] = false; setErrores([...errores]) }}
                    format='dd/MM/yyyy'
                    error={errores[8]}
                    helperText={errores[8] ? 'La fecha no puede quedar vacía' : (errores[9]?'La fecha de cierre debe ser mayor o igual a la fecha de inicio':null)}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Cerrado:</Typography>
                <Autocomplete
                    value={cerrado}
                    onChange={(e, v) => setCerrado(v)}
                    options={
                        ['Si', 'No'].map(option => option)
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Servidor:</Typography>
                <Autocomplete
                    value={servidor}
                    onChange={(e, v) => setServidor(v)}
                    options={
                        ['Ninguno', 'España', 'Amazon'].map(option => option)
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() => validar()}>
                    Aceptar
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => limpiar()} >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoCrear;