import React, { useEffect } from 'react';
import Logo from '../logo.png'
import { IconButton, Drawer, AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import {
  Link
} from "react-router-dom";

function TemporaryDrawer(props) {
  const theme = props.theme;
  const classes = props.classes;
  const [open, setOpen] = props.open;
  const [tablaEstudios, setTablaEstudios] = props.tablaEstudios;

  useEffect(() => {
    setOpen(true);
    setTimeout(() => { setOpen(false) }, 1000);
  }, [setOpen]);

  return (
    <React.Fragment>
      <Drawer anchor='left' open={open} onClose={() => setOpen(false)} style={{ zIndex: 2000 }}>
        <AppBar position='static'>
          <Toolbar style={{ width: 300 }}>
            <IconButton
              style={{ marginRight: theme.spacing(2) }}
              color='inherit'
              edge='start'
              onClick={() => setOpen(false)}
            >
              <MenuIcon />
            </IconButton>
            <a className={classes.a} href={'https://www.centronacionaldeconsultoria.com/'}>
              <img src={Logo} alt='logo' className={classes.logo} />
            </a>
          </Toolbar>
        </AppBar>
        <List>
          {['Tabla de estudios', 'Modalidad de pago'].map((option, index) =>
            <ListItem component={Link} to={index === 0 ? '/tabla-estudios' : '/modalidad-pago'} button selected={(index === 0 && tablaEstudios) || (index === 1 && !tablaEstudios)} onClick={() => { setTablaEstudios(index === 0); setOpen(false) }}>
              <ListItemIcon>
                {index === 0 ? <CreateOutlinedIcon /> : <InsertChartOutlinedIcon />}
              </ListItemIcon>
              <ListItemText primary={option} />
            </ListItem>
          )}
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default TemporaryDrawer;