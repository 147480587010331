import React from 'react';
import { CSVLink } from 'react-csv';
import { Tooltip, IconButton} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';


function ExportCSV(props) {
    const fileName = props.filename;
    const data = props.data;
    

    return (
        <Tooltip title="Descargar Excel">
            <IconButton color="primary" aria-label="Descargar CSV" component="span">
            <CSVLink
                    data={data}
                    filename={fileName}
                    separator={";"}
                    style={{ "textDecoration": "none", "color": '#004D40' }}
                >
                    <DescriptionOutlinedIcon />
                </CSVLink>
            </IconButton>
        </Tooltip>
    );
}

export default ExportCSV;