import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    Container,
    IconButton,
    Button
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { format, addDays } from 'date-fns';

import ClearIcon from '@material-ui/icons/Clear';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import NumberFormat from 'react-number-format';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import ExportCSV from '../ExportCSV';


import DialogoCrear from './DialogoCrear';
import DialogoEditar from './DialogoEditar';
import DialogoEliminar from './DialogoEliminar';

const useStyles = makeStyles({
    table: {
        minWidth: 2000,
    },
    menuItem: {
        fontSize: 14
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function Tabla(props) {
    const { theme, validRole, getAccessTokenWithRefresh, API_DEFAULT, setShowBackdrop, setMessage, setSeverity, setSnack } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();

    const [openCrear, setOpenCrear] = useState(false);
    const [estudioEditar, setEstudioEditar] = useState({});
    const [openEditar, setOpenEditar] = useState(false);
    const [estudioEliminar, setEstudioEliminar] = useState({});
    const [openEliminar, setOpenEliminar] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [datos, setDatos] = useState([]);
    const [filtros, setFiltros] = useState(Array(12).fill(''));

    const rows = datos.filter(dato =>
        (`${dato.centro_costos}`.toLowerCase().startsWith(filtros[0].toLowerCase())) &&
        (`${dato.subcentro_costos}`.toLowerCase().startsWith(filtros[1].toLowerCase())) &&
        (dato.nombre_integra.toLowerCase().includes(filtros[2].toLowerCase())) &&
        (filtros[3] === 'Todos' ? true : dato.tipo_campo.toLowerCase().includes(filtros[3].toLowerCase())) &&
        (parseInt(dato.muestra) >= parseInt(filtros[4] * 1)) &&
        (parseInt(dato.meta) >= parseInt(filtros[5] * 1)) &&
        (parseFloat(dato.precio_venta) >= parseFloat(filtros[6] * 1)) &&
        (parseFloat(dato.presupuesto_legalizaciones === null ? 0 : dato.presupuesto_legalizaciones) >= parseFloat(filtros[7] * 1)) &&
        (parseFloat(dato.presupuesto) >= parseFloat(filtros[8] * 1)) &&
        (filtros[9] === 'Todos' ? true : dato.tipo_estudio.toLowerCase().includes(filtros[9].toLowerCase())) &&
        (filtros[10] === 'Todos' ? true : (dato.cerrado ? 'Si' : 'No').toLowerCase().includes(filtros[10].toLowerCase())) &&
        (filtros[11] === 'Todos' ? true : dato.servidor.toLowerCase().includes(filtros[11].toLowerCase()))
    );

    const compareObjects = (object1, object2, key) => {
        const obj1 = object1[key].toUpperCase()
        const obj2 = object2[key].toUpperCase()

        if (obj1 < obj2) {
            return -1
        }
        if (obj1 > obj2) {
            return 1
        }
        return 0
    }

    const obtenerEstudios = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/estudios/gestor_estudios/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    obtenerEstudios(newAccess);
                }
            }
            else {
                filtros[3] = 'Todos'
                for (let i = 9; i < 11; i++) {
                    filtros[i] = 'Todos';
                }
                setFiltros([...filtros]);
                setDatos(res.sort((first, second) => compareObjects(first, second, 'nombre_integra')));
                setShowBackdrop(false);
            }
        });
    }

    useEffect(() => { if (validRole) { obtenerEstudios() } }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='Table-body'>
            <Container component='main' maxWidth='xl'>
                <Card>
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5), alignItems: 'flex-start', justifyContent: 'space-between' }}
                    classes={{
                        title: classes.cardTitle
                    }}
                    title={
                        <React.Fragment>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant='h6' style={{ marginRight: theme.spacing(1) }}>Tabla de estudios</Typography>
                                <ExportCSV
                                    filename={"Estudios"}
                                    data={rows}
                                />
                            </div>

                        <Button size= 'small' variant='contained' color='primary' onClick={() => setOpenCrear(true)}>Crear estudio</Button>
        </React.Fragment>
    }
/>
                    <Divider />
                    <CardContent>
                        <TableContainer component={Paper} variant='outlined' style={{ borderBottom: '0px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'></TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Centro de costos</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Subcentro de costos</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Nombre INTEGRA</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Tipo de campo</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Muestra</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Meta</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Precio de venta</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Presupuesto legalizaciones</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Presupuesto</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Tipo de estudio</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Fecha de inicio</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Fecha de cierre</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Cerrado</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Servidor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} component='th' scope='row' align='center'>
                                            <IconButton
                                                size='small'
                                                onClick={() => {
                                                    for (let i = 0; i < 8; i++) {
                                                        filtros[i] = '';
                                                    }
                                                    filtros[3] = 'Todos';
                                                    for (let i = 9; i < 11; i++) {
                                                        filtros[i] = 'Todos';
                                                    }
                                                    setFiltros([...filtros]);
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <NumberFormat
                                                customInput={TextField}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                value={filtros[0]}
                                                onValueChange={(e) => { filtros[0] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <NumberFormat
                                                customInput={TextField}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                value={filtros[1]}
                                                onValueChange={(e) => { filtros[1] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px'
                                                    }
                                                }}
                                                value={filtros[2]}
                                                onChange={(e) => { filtros[2] = e.target.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[3]}
                                                onChange={(e, v) => { filtros[3] = v; setFiltros([...filtros]) }}
                                                options={
                                                    ['Todos', 'Presencial', 'Telefónico', 'Cualitativo', 'Mixto', 'No asignado'].map(option => option)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <NumberFormat
                                                customInput={TextField}
                                                thousandSeparator={true}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                value={filtros[4]}
                                                onValueChange={(e) => { filtros[4] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <NumberFormat
                                                customInput={TextField}
                                                thousandSeparator={true}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                value={filtros[5]}
                                                onValueChange={(e) => { filtros[5] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <CurrencyTextField
                                                value={filtros[6]}
                                                onChange={(e, v) => { filtros[6] = v; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                modifyValueOnWheel={false}
                                                outputFormat='string'
                                                decimalPlaces={0}
                                                minimumValue={0}
                                                InputProps={{
                                                    style: {
                                                        paddingLeft: 8
                                                    }
                                                }} // eslint-disable-next-line
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <CurrencyTextField
                                                value={filtros[7]}
                                                onChange={(e, v) => { filtros[7] = v; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                modifyValueOnWheel={false}
                                                outputFormat='string'
                                                decimalPlaces={0}
                                                minimumValue={0}
                                                InputProps={{
                                                    style: {
                                                        paddingLeft: 8
                                                    }
                                                }} // eslint-disable-next-line
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <CurrencyTextField
                                                value={filtros[8]}
                                                onChange={(e, v) => { filtros[8] = v; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                modifyValueOnWheel={false}
                                                outputFormat='string'
                                                decimalPlaces={0}
                                                minimumValue={0}
                                                InputProps={{
                                                    style: {
                                                        paddingLeft: 8
                                                    }
                                                }} // eslint-disable-next-line
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[9]}
                                                onChange={(e, v) => { filtros[9] = v; setFiltros([...filtros]) }}
                                                options={
                                                    ['Todos', 'Población general', 'Base de clientes', 'ECAR', 'Otros', 'Empresas'].map(option => option)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>

                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>

                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[10]}
                                                onChange={(e, v) => { filtros[10] = v; setFiltros([...filtros]) }}
                                                options={
                                                    ['Todos', 'Si', 'No'].map(option => option)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[11]}
                                                onChange={(e, v) => { filtros[11] = v; setFiltros([...filtros]) }}
                                                options={
                                                    ['Todos', 'Ninguno', 'España', 'Amazon'].map(option => option)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <TableRow key={row.cedula}>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} component='th' scope='row' align='center'>
                                                <div style={{ display: 'flex' }}>
                                                    <Typography style={{ color: '#1565C0', fontWeight: 500, fontSize: 14, cursor: 'pointer', paddingRight: theme.spacing(1.2) }} onClick={() => { setEstudioEditar(row); setOpenEditar(true) }}>Editar</Typography>
                                                    <Typography style={{ color: '#C62828', fontWeight: 500, fontSize: 14, cursor: 'pointer' }} onClick={() => { setEstudioEliminar(row); setOpenEliminar(true) }}>Eliminar</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 100 }} align='right'> {row.centro_costos} </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'>{row.subcentro_costos}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.nombre_integra}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.tipo_campo}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'><NumberFormat value={row.muestra} displayType={'text'} thousandSeparator={true} /></TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'><NumberFormat value={row.meta} displayType={'text'} thousandSeparator={true} /></TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'><NumberFormat value={row.precio_venta} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'><NumberFormat value={row.presupuesto_legalizaciones === null ? 0 : row.presupuesto_legalizaciones} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'><NumberFormat value={row.presupuesto} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.tipo_estudio}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 140 }} align='left'>{row.fecha_inicio ? format(addDays(new Date(row.fecha_inicio), 1), 'dd/MM/yyyy') : 'No asignado'}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 140 }} align='left'>{row.fecha_cierre ? format(addDays(new Date(row.fecha_cierre), 1), 'dd/MM/yyyy') : 'No asignado'}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.cerrado ? 'Si' : 'No'}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.servidor}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component={Paper}
                            variant='outlined'
                            style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(e, n) => setPage(n)}
                            onChangeRowsPerPage={(e) => { setRowsPerPage(parseInt(e.target.value, 10)); setPage(0) }}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </CardContent>
                </Card>
            </Container>
            <DialogoCrear
                theme={theme}
                open={[openCrear, setOpenCrear]}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                setShowBackdrop={setShowBackdrop}
                obtenerEstudios={obtenerEstudios}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            />
            <DialogoEditar
                theme={theme}
                estudio={estudioEditar}
                open={[openEditar, setOpenEditar]}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                setShowBackdrop={setShowBackdrop}
                obtenerEstudios={obtenerEstudios}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            />
            <DialogoEliminar
                theme={theme}
                estudio={estudioEliminar}
                open={[openEliminar, setOpenEliminar]}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                setShowBackdrop={setShowBackdrop}
                obtenerEstudios={obtenerEstudios}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            />
        </div>
    );
}

export default Tabla;