import React, { useState, useEffect } from 'react';
import { Container, Card, Collapse, CardHeader, Typography, CardContent, Divider, Grid, Button, TextField, TableContainer, TableBody, Paper, Table, TableHead, TableRow, TableCell, Popover } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { format, addDays } from 'date-fns';
import NumberFormat from 'react-number-format';

import DialogoCrear from './DialogoCrear';
import DialogoEditar from './DialogoEditar';
import DialogoEliminar from './DialogoEliminar';

const useStyles = makeStyles({
    table: {
        minWidth: 600,
    },
    menuItem: {
        fontSize: 14
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

function Pago(props) {
    const { theme, API_DEFAULT, getAccessTokenWithRefresh, validRole, setShowBackdrop, setMessage, setSeverity, setSnack } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();
    const [estudio, setEstudio] = useState(null);
    const [estudios, setEstudios] = useState([]);
    const [tipoCampo, setTipoCampo] = useState('');
    const [muestra, setMuestra] = useState('');
    const [meta, setMeta] = useState('');
    const [tipoEstudio, setTipoEstudio] = useState('');
    const [modalidades, setModalidades] = useState([]);
    const [modalidadEditar, setModalidadEditar] = useState({});
    const [modalidadEliminar, setModalidadEliminar] = useState({});

    const [openCrear, setOpenCrear] = useState(false);
    const [openEditar, setOpenEditar] = useState(false);
    const [openEliminar, setOpenEliminar] = useState(false);

    const [modalidadVer, setModalidadVer] = useState({});
    const [openPopover, setOpenPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const compareObjects = (object1, object2, key) => {
        const obj1 = object1[key].toUpperCase()
        const obj2 = object2[key].toUpperCase()

        if (obj1 < obj2) {
            return 1
        }
        if (obj1 > obj2) {
            return -1
        }
        return 0
    }

    const fetchEstudios = async (access = accessToken) => {
        const res = await fetch(`${API_DEFAULT}/estudios/lista_estudios/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        res
            .json()
            .then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        fetchEstudios(newAccess);
                    }
                }
                else {
                    setEstudios(res);
                }
            });
    }

    const fetchInfoEst = async (access = accessToken) => {
        if (estudio !== null) {
            const res = await fetch(`${API_DEFAULT}/estudios/informacion_estudio/nombre_integra=${estudio}/`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            });
            res
                .json()
                .then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);

                            fetchInfoEst(newAccess);
                        }
                    }
                    else {
                        setTipoCampo(res.tipo_campo);
                        setMuestra(res.muestra);
                        setMeta(res.meta);
                        setTipoEstudio(res.tipo_estudio);
                    }
                });
        }
        else {
            setTipoCampo('');
            setMuestra('');
            setMeta('');
            setTipoEstudio('');
        }
    }

    const fetchModEst = async (access = accessToken) => {
        setShowBackdrop(true);
        if (estudio !== null) {
            const res = await fetch(`${API_DEFAULT}/estudios/modalidades_estudio/nombre_integra=${estudio}/`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            });
            res
                .json()
                .then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);

                            fetchModEst(newAccess);
                        }
                    }
                    else {
                        setModalidades(res.sort((first, second) => compareObjects(first, second, 'fecha')));
                        setShowBackdrop(false);
                    }
                });
        }
        else {
            setModalidades([]);
            setShowBackdrop(false);
        }
    }

    useEffect(() => {
        if (validRole) { fetchEstudios() }
    }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchInfoEst();
        fetchModEst();
    }, [estudio]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='Pago-body'>
            <Container component='main' maxWidth='md'>
                <Card>
                    <CardHeader
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                        classes={{
                            title: classes.cardTitle
                        }}
                        title={
                            <Typography variant='h6'>Modalidad de pago</Typography>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Grid container
                            direction='row'
                            alignItems='center'
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <Typography variant='body1'>Seleccione el estudio:</Typography>
                                <Autocomplete
                                    value={estudio}
                                    onChange={(event, value) => {
                                        setEstudio(value);
                                    }}
                                    options={
                                        estudios.map(option => option.nombre_integra)
                                    }
                                    noOptionsText='No hay coincidencias'
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='body1'>Muestra:</Typography>
                                <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    style={{ backgroundColor: !estudio ? '#E0E0E0' : null, borderRadius: 4 }}
                                    value={muestra}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    inputProps={{
                                        readOnly: true,
                                        style: {
                                            textAlign: 'right'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='body1'>Meta:</Typography>
                                <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    style={{ backgroundColor: !estudio ? '#E0E0E0' : null, borderRadius: 4 }}
                                    value={meta}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    inputProps={{
                                        readOnly: true,
                                        style: {
                                            textAlign: 'right'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container
                            direction='row'
                            alignItems='center'
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <Typography variant='body1'>Tipo de campo:</Typography>
                                <TextField
                                    style={{ backgroundColor: !estudio ? '#E0E0E0' : null, borderRadius: 4 }}
                                    value={tipoCampo}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <Typography variant='body1'>Tipo de estudio:</Typography>
                                <TextField
                                    style={{ backgroundColor: !estudio ? '#E0E0E0' : null, borderRadius: 4 }}
                                    value={tipoEstudio}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Collapse in={estudio !== null}>
                            <TableContainer style={{ marginTop: theme.spacing(2) }} component={Paper} variant='outlined'>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={5} style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography variant='body1' style={{ fontSize: 16, fontWeight: 500 }}>Historico modalidades de pago</Typography>
                                                    <Button size='small' variant='contained' color='primary' onClick={() => setOpenCrear(true)}>Crear modalidad</Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'></TableCell>
                                            <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Fecha desde la que aplica la modalidad</TableCell>
                                            <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Modalidad de pagos</TableCell>
                                            <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Parámetros</TableCell>
                                            <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Observación de pago</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {modalidades.length > 0 ? modalidades.map((row, index) =>
                                            <TableRow key={row.id}>
                                                <TableCell style={{ paddingTop: 14, paddingBottom: 14, borderBottom: (index + 1) === modalidades.length ? 'unset' : null }} component='th' scope='row' align='center'>
                                                    <div style={{ display: 'flex' }}>
                                                        <Typography style={{ color: '#1565C0', fontWeight: 500, fontSize: 14, cursor: 'pointer', paddingRight: theme.spacing(1.2) }} onClick={() => { setModalidadEditar(row); setOpenEditar(true) }}>Editar</Typography>
                                                        <Typography style={{ color: '#C62828', fontWeight: 500, fontSize: 14, cursor: 'pointer' }} onClick={() => { setModalidadEliminar(row); setOpenEliminar(true) }}>Eliminar</Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ paddingTop: 14, paddingBottom: 14, borderBottom: (index + 1) === modalidades.length ? 'unset' : null }} align='right'>{row.fecha === '1999-01-01' ? 'Inicio del estudio' : format(addDays(new Date(row.fecha), 1), 'dd/MM/yyyy')}</TableCell>
                                                <TableCell style={{ paddingTop: 14, paddingBottom: 14, borderBottom: (index + 1) === modalidades.length ? 'unset' : null }} align='left'>{row.modalidad_pago}</TableCell>
                                                <TableCell style={{ paddingTop: 14, paddingBottom: 14, borderBottom: (index + 1) === modalidades.length ? 'unset' : null }} align='right'>
                                                    <Typography style={{ color: '#1565C0', fontWeight: 500, fontSize: 14, cursor: 'pointer', paddingRight: theme.spacing(1.2) }} onClick={(e) => { setModalidadVer(row); setOpenPopover(true); setAnchorEl(e.currentTarget) }}>Ver</Typography>
                                                </TableCell>
                                                <TableCell style={{ width: '40%', paddingTop: 14, paddingBottom: 14, borderBottom: (index + 1) === modalidades.length ? 'unset' : null }} align='left'>{row.observacion_pago}</TableCell>
                                            </TableRow>
                                        )
                                            :
                                            <TableRow style={{ height: 48.8 }}>
                                                <TableCell style={{ paddingTop: 14, paddingBottom: 14, borderBottom: 'unset' }} align='right' colSpan={4}>No existen modalidades de pago asociadas al estudio seleccionado.</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </CardContent>
                </Card>
            </Container>
            <DialogoCrear
                theme={theme}
                open={[openCrear, setOpenCrear]}
                estudio={estudio}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                setSnack={setSnack}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setShowBackdrop={setShowBackdrop}
                fetchModEst={fetchModEst}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            />
            <DialogoEditar
                theme={theme}
                open={[openEditar, setOpenEditar]}
                modalidadEditar={modalidadEditar}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                setSnack={setSnack}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setShowBackdrop={setShowBackdrop}
                fetchModEst={fetchModEst}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            />
            <DialogoEliminar
                theme={theme}
                open={[openEliminar, setOpenEliminar]}
                modalidad={modalidadEliminar}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                setSnack={setSnack}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setShowBackdrop={setShowBackdrop}
                fetchModEst={fetchModEst}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            />
            {modalidadVer.modalidad_pago !== undefined ?
                <Popover
                    open={openPopover}
                    onClose={() => setOpenPopover(false)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div style={{ margin: theme.spacing(1.5) }}>
                        {modalidadVer.modalidad_pago === 'Normal' ?
                            <React.Fragment>
                                <Typography variant='body2'><span style={{ fontWeight: 500 }}>Valor de la encuesta:</span> <NumberFormat value={modalidadVer.parametros.valor_encuesta} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Typography variant='body2'><span style={{ fontWeight: 500 }}>Valor de la encuesta antes del 80%:</span> <NumberFormat value={modalidadVer.parametros.valor_80} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography>
                                <Typography variant='body2' style={{ marginTop: theme.spacing(0.25) }}><span style={{ fontWeight: 500 }}>Valor de la encuesta después del 80% (20% restante):</span> <NumberFormat value={modalidadVer.parametros.valor_20} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Typography>
                            </React.Fragment>
                        }
                    </div>
                </Popover>
                :
                null
            }
        </div>
    );
}

export default Pago;