import React, { useState } from 'react';
import { Dialog, DialogTitle, Divider, DialogContent, DialogActions, Button, Typography, TextField, Collapse, FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

function DialogoCrear(props) {
    const { theme, estudio, fetchModEst, API_DEFAULT, getAccessTokenWithRefresh, setMessage, setSeverity, setSnack, setShowBackdrop } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [open, setOpen] = props.open;
    const [modalidad, setModalidad] = useState(null);
    const [fechaInicio, setFechaInicio] = useState(true);
    const [fechaPersonalizada, setFechaPersonalizada] = useState(null);
    const [valorEncuesta, setValorEncuesta] = useState('');
    const [observacionPrecio, setObservacionPrecio] = useState('');
    const [valor80, setValor80] = useState('');
    const [valor20, setValor20] = useState('');
    const [errores, setErrores] = useState(Array(4).fill(false));

    const limpiar = () => {
        setModalidad(null);
        setFechaInicio(true);
        setFechaPersonalizada(null);
        setObservacionPrecio('')
        limpiarValores();
        setErrores(Array(4).fill(false));
        setOpen(false);
    }

    const limpiarValores = () => {
        setValorEncuesta('');
        setValor80('');
        setValor20('');
        for (let i = 2; i < 4; i++) {
            errores[i] = false;
        }
        setErrores([...errores]);
    }

    const validar = () => {
        let errorInformacion = false;
        if (!fechaInicio && fechaPersonalizada === null) {
            errorInformacion = true;
            errores[0] = true;
        }
        if (modalidad === null) {
            errorInformacion = true;
            errores[1] = true;
        }
        if (modalidad === 'Normal' && (valorEncuesta === '' || valorEncuesta <= 0)) {
            errorInformacion = true;
            errores[2] = true;
        }
        if (modalidad === '80% - 20%' && (valor80 === '' || valor80 <= 0)) {
            errorInformacion = true;
            errores[2] = true;
        }
        if (modalidad === '80% - 20%' && (valor20 === '' || valor20 <= 0)) {
            errorInformacion = true;
            errores[3] = true;
        }
        if (errorInformacion) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            crear();
        }
    }

    const crear = async (access = accessToken) => {
        setShowBackdrop(true);
        
        const res = await fetch(`${API_DEFAULT}/estudios/modalidades/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'estudio': estudio,
                'fecha': fechaInicio ? '1999-01-01' : fechaPersonalizada.toISOString().split('T')[0],
                'modalidad_pago': modalidad === 'Normal' ? 0 : (modalidad === '80% - 20%' ? 1 : 2),
                'parametros':
                    modalidad === 'Normal' ?
                        {
                            'valor_encuesta': parseFloat(valorEncuesta)
                        }
                        :
                        (modalidad === '80% - 20%' ?
                            {
                                'valor_80': parseFloat(valor80),
                                'valor_20': parseFloat(valor20)
                            }
                            :
                            []
                        ),
                'observacion_pago': observacionPrecio
            })
        });

        if (res.ok) {
            fetchModEst(access);
            setMessage('Se ha creado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            limpiar();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        crear(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>Crear modalidad de pago</DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant='body1'>Seleccione la fecha desde la que aplica el cambio:</Typography>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={0}>
                        <FormControlLabel
                            style={{ marginRight: 0, marginLeft: -6, marginTop: -1, marginBottom: !errores[0] ? -1 : 22 }}
                            control={<Checkbox color='primary' checked={fechaInicio} onChange={() => { setFechaInicio(!fechaInicio); setFechaPersonalizada(null); errores[0] = false; setErrores([...errores]) }} />}
                            label={<span style={{ color: !fechaInicio ? '#00000061' : null }}>Desde el inicio del estudio</span>}
                        />
                    </Grid>
                    <Grid item xs>
                        <KeyboardDatePicker
                            disabled={fechaInicio}
                            style={{ backgroundColor: fechaInicio ? '#E0E0E0' : null, borderRadius: 4 }}
                            clearable
                            autoOk
                            inputVariant='outlined'
                            size='small'
                            variant='inline'
                            fullWidth
                            value={fechaPersonalizada}
                            placeholder='dd/mm/yyyy'
                            onChange={date => { setFechaPersonalizada(date); errores[0] = false; setErrores([...errores]) }}
                            format='dd/MM/yyyy'
                            error={errores[0]}
                            helperText={errores[0] ? 'La fecha no puede quedar vacía' : null}
                        />
                    </Grid>
                </Grid>
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Seleccione la modalidad de pago:</Typography>
                <Autocomplete
                    value={modalidad}
                    onChange={(e, v) => { setModalidad(v); limpiarValores(); errores[1] = false; setErrores([...errores]) }}
                    options={
                        ['Normal', '80% - 20%', 'Escalonado'].map(option => option)
                    }
                    noOptionsText='No hay coincidencias'
                    getOptionDisabled={(option) => option === 'Escalonado'}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[1]}
                            helperText={errores[1] ? 'La modalidad no puede quedar vacía' : null}
                        />
                    )}
                />
                <Collapse in={modalidad === 'Normal'} mountOnEnter unmountOnExit>
                    <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Valor de la encuesta:</Typography>
                    <CurrencyTextField
                        value={valorEncuesta}
                        onChange={(e, v) => { setValorEncuesta(v); errores[2] = false; setErrores([...errores]) }}
                        size='small'
                        variant='outlined'
                        fullWidth
                        modifyValueOnWheel={false}
                        outputFormat='string'
                        decimalPlaces={0}
                        minimumValue={0}
                        error={errores[2]}
                        helperText={errores[2] ? 'El valor de la encuesta no puede quedar vacío o ser cero' : null}
                    />
                </Collapse>
                <Collapse in={modalidad === '80% - 20%'} mountOnEnter unmountOnExit>
                    <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Valor de la encuesta antes del 80%:</Typography>
                    <CurrencyTextField
                        value={valor80}
                        onChange={(e, v) => { setValor80(v); errores[2] = false; setErrores([...errores]) }}
                        size='small'
                        variant='outlined'
                        fullWidth
                        modifyValueOnWheel={false}
                        outputFormat='string'
                        decimalPlaces={0}
                        minimumValue={0}
                        error={errores[2]}
                        helperText={errores[2] ? 'El valor de la encuesta no puede quedar vacío o ser cero' : null}
                    />
                    <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Valor de la encuesta después del 80% (20% restante):</Typography>
                    <CurrencyTextField
                        value={valor20}
                        onChange={(e, v) => { setValor20(v); errores[3] = false; setErrores([...errores]) }}
                        size='small'
                        variant='outlined'
                        fullWidth
                        modifyValueOnWheel={false}
                        outputFormat='string'
                        decimalPlaces={0}
                        minimumValue={0}
                        error={errores[3]}
                        helperText={errores[3] ? 'El valor de la encuesta no puede quedar vacío o ser cero' : null}
                    />
                </Collapse>
                <Collapse in={modalidad === 'Escalonado'} mountOnEnter unmountOnExit>
                    <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Escalones...</Typography>
                </Collapse>
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Observación de precios:</Typography>
                <TextField
                            onChange={(e) => {setObservacionPrecio(e.target.value)}}
                            size='small'
                            variant={'outlined'}
                            fullWidth
                            value={observacionPrecio}
                        />
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() => validar()}>
                    Aceptar
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => limpiar()} >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoCrear;